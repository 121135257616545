<template>
  <div class="register-container">
    <el-form
        ref="registerForm"
        :model="form"
        :rules="rules"
        class="register-form"
        auto-complete="on"
        label-position="left"
    >
      <div class="logo-container"></div>

      <div class="font-bold text-blue-600 text-center mt-8 mb-4">
        REGISTRATI E CREA IL TUO MODELLO!
      </div>
      <span class="my-2 block text-center text-sm">E' richiesto l'ultima versione aggiornata del sistema operativo ( Windows / OSX ) e chrome all'ultima versione</span>
      <el-form-item label="Nome" prop="name">
        <el-input
            prefix-icon="el-icon-user"
            v-model="form.name"
            placeholder="Nome"
            name="name"
            type="text"
            tabindex="1"
            auto-complete="on"
        />
      </el-form-item>
      <el-form-item label="Cognome" prop="surname">
        <el-input
            prefix-icon="el-icon-user"
            v-model="form.surname"
            placeholder="Cognome"
            name="surname"
            type="text"
            tabindex="1"
            auto-complete="on"
        />
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input
            prefix-icon="el-icon-user"
            v-model="form.email"
            placeholder="Email"
            name="email"
            type="text"
            tabindex="1"
            auto-complete="on"
        />
      </el-form-item>

      <el-form-item label="Password" prop="password">
        <el-input
            prefix-icon="el-icon-lock"
            show-password
            ref="password"
            v-model="form.password"
            placeholder="Password"
            name="password"
            tabindex="2"
            auto-complete="on"
        />
      </el-form-item>
      <el-form-item label="Conferma Password" prop="password_confirmation">
        <el-input
            prefix-icon="el-icon-lock"
            show-password
            ref="password"
            v-model="form.password_confirmation"
            placeholder="Conferma Password"
            name="confirm_password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="onRegister"
        />
      </el-form-item>

      <el-form-item label="Informativa Privacy" prop="privacy_policy">
        <el-checkbox-group  v-model="form.privacy_policy">
          <el-checkbox value="policy checked">
            <p class="my-2 block text-center text-sm text-wrap">
              Confermo di aver letto e accetto la presente
              <a target="blank" href="/informativa_privacy.pdf"> <strong>Informativa sulla privacy </strong></a>
            </p>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-button
          :loading="isLoading"
          type="primary"
          class="mt-5"
          style="width: 100%; margin-bottom: 30px"
          @click.native.prevent="onRegister"
      >Registrati
      </el-button
      >
      <div class="tips">
        <p> Hai già un account? <a class="font-bold text-blue-600 cursor-pointer" @click="goToLogin">Esegui il login</a>
        </p>
      </div>
    </el-form>
    <img alt="welcome" class="w-full col-span-2 object-contain h-full" src="../../../assets/welcome.jpg">
  </div>

</template>

<script>
import authService from '@/modules/auth/services/auth.services';

export default {
  name: "Login",
  data() {

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Il campo password è obbligatorio'));
      } else if (value !== this.form.password) {
        callback(new Error('La Password non corrisponde!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        name:"",
        surname:"",
        email: "",
        password: "",
        password_confirmation: "",
        privacy_policy: [],
      },
      rules: {
        name:{
          required:true,
          message:"Il nome è obbligatorio",
          trigger: ['blur', 'change'],
        },
        surname:{
          required:true,
          message:"Il cognome è obbligatorio",
          trigger: ['blur', 'change'],
        },
        privacy_policy: [
          {
            type: 'array',
            required: true,
            message: 'Spuntare la casella per confermare presa visione dell\'informativa sulla privacy',
            trigger: 'change',
          },
        ],
        email: [{
          required: true,
          message: 'Il campo email è obbligatorio',
          trigger: 'blur',
        }, {
          type: 'email',
          message: 'Inserisci un indirizzo email corretto',
          trigger: ['blur', 'change'],
        }],
        password: [{
          required: true,
          message: 'Il campo password è obbligatorio',
          trigger: 'blur',
        }, {
          min: 6,
          message: 'La lunghezza della password deve essere di almeno 5 caratteri',
          trigger: 'blur',
        }],
        password_confirmation: [
          {validator: validatePass2, trigger: 'blur'}
        ],
      },
      isLoading: false,
    };
  },
  methods: {
    /**
     * @desc On Register
     */
    onRegister() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this._register();
        } else {
          return false;
        }
      });
    },
    /**
     * @desc Register
     * @returns {Promise<void>}
     * @private
     */
    async _register() {
      this.isLoading = true;
      // eslint-disable-next-line no-unused-vars
      const { privacy_policy, ...formWithoutPrivacyPolicy } = this.form;
      await authService.register(formWithoutPrivacyPolicy).then((registerData) => {
        this.$store.dispatch('auth/loggedIn', {
          accessToken: registerData.data.data.access_token,
          user: registerData.data.data.user,
        });
        this.$router.push({path: '/projects'});
      }).catch((error) => {
        this.$notificationService.error(error)
        this.$store.dispatch('auth/resetState');
      }).finally(() => this.isLoading = false)
    },
    /**
     * @description Go to login page
     */
    goToLogin() {
      this.$router.push({path: '/login'})
    }
  },
};
</script>

<style lang="scss" scoped>

.el-checkbox__label p{
  text-wrap: wrap;
}

.register-container {
  @apply grid grid-cols-3;
  min-height: 100vh;
  align-items: stretch;
  justify-items: center;
  width: 100%;
  overflow: hidden;

  .register-form {
    align-self: center;
    width: 100%;
    max-width: 100%;
    padding: 35px;
    margin: 0 auto;
    overflow: hidden;

    .logo-container {
      background-image: url("../../../assets/logoOspedaleFuturo.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 200px;
    }
  }

  .tips {
    font-size: 14px;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
}
</style>
